import { action, computed, observable, ObservableMap, runInAction } from 'mobx';

import { getData } from '~api';
import { IReportsHistoryItem } from '~common';
import { openExperimentsModal } from '~containers';
import { ReportsTransportLayer } from '~services/reportsApi';
// eslint-disable-next-line import/no-named-as-default
import FetchPaginationStore from '~store/fetchPaginationStore';
import { showErrorNotification, showErrorNotificationInPromise } from '~utils';

export class ReportsStore {
  apiLayer = new ReportsTransportLayer();

  @observable
  reportTemplates: IReportsHistoryItem[] = [];

  @observable
  reportsStatuses = [];

  @observable reports: IReportsHistoryItem[] = [];

  @observable isFetching = false;

  @observable isMigrationProcessing = false;

  @observable reportsHistoryItemsCount = 0;

  @observable editableReport = null;

  @observable allItemsCount = 0;

  @action
  setAllItemsCount = count => {
    this.allItemsCount = count;
  };

  @computed
  get reportsLength() {
    return this.reports.length;
  }

  @action
  setIsFetching = (isFetching: boolean) => {
    this.isFetching = isFetching;
  };

  @action
  fetchDictionaries = () => {
    return this.apiLayer.getDictionaries().then(data => {
      this.reportTemplates = getData(data);
    });
  };

  @action
  fetchReports = (defaultFilter = 'sort=-createdAt') => {
    const { offset, setPageOffset } = FetchPaginationStore;
    const isNewFetch = offset === 0;

    if (isNewFetch) {
      this.setIsFetching(true);
      this.reports = [];
      this.setAllItemsCount(0);
    }

    return this.apiLayer
      .getReports(defaultFilter)
      .then(({ data: reportsHistory = [], meta }) => {
        if (isNewFetch) {
          this.reports = reportsHistory;
        } else {
          this.reports = [...this.reports, ...reportsHistory];
        }
        this.setAllItemsCount(meta.count);
        setPageOffset(offset + 1);
        return Promise.resolve(reportsHistory);
      })
      .catch(reason => {
        showErrorNotification('Ошибка загрузки истории отчетов', reason);
        return Promise.reject(reason);
      })
      .finally(() => {
        this.setIsFetching(false);
      });
  };

  @action
  setEditableReport = async value => {
    this.editableReport = { ...value };
    await openExperimentsModal();
  };

  @action
  createReport = report => {
    const { resetPaginationParams } = FetchPaginationStore;
    return this.apiLayer
      .createReport(report)
      .then(({ data }) => {
        runInAction(() => {
          this.reports.unshift(data);
          resetPaginationParams();
          this.fetchReports();
        });
        return Promise.resolve(data);
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка создания отчета',
        }),
      );
  };
}

export default new ReportsStore();
