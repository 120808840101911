import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Input, Tooltip } from 'antd';
import clsx from 'classnames';
import _draftJs from 'draft-js';
import isPunycode from 'is-punycode';
import { toUnicode } from 'punycode-url';
import React, { Component } from 'react';

import URLUtils from '../../../utils/URLUtils';
import styles from '../styles.styl';

interface IProps {
  fromInline?: boolean;
  getEditorState(): void;
  onOverrideContent(data: undefined): void;
  onRemoveLinkAtSelection(): void;
  placeholder?: string;
  setEditorState(state: any): void;
  theme: any;
}

interface IState {
  isInvalid: boolean;
  nofollow?: boolean;
  value: string;
}

const extendedCreateLinkFromSelection = function createLinkAtSelection(
  editorState,
  url,
  nofollow = false,
) {
  const contentState = editorState
    .getCurrentContent()
    .createEntity('LINK', 'MUTABLE', { url, nofollow });

  const entityKey = contentState.getLastCreatedEntityKey();
  const withLink = _draftJs.RichUtils.toggleLink(
    editorState,
    editorState.getSelection(),
    entityKey,
  );
  return _draftJs.EditorState.forceSelection(withLink, editorState.getSelection());
};

export default class AddLinkForm extends Component<IProps, IState> {
  static defaultProps = {
    placeholder: 'Введите ссылку и нажмите enter',
    value: '',
  };

  input: HTMLInputElement;

  constructor(props) {
    super(props);

    console.log('cr props', props);

    this.state = {
      value: props.value,
      nofollow: props.nofollow,
      isInvalid: false,
    };
  }

  componentDidMount() {
    this.input.focus();
  }

  onRef = node => {
    this.input = node;
  };

  onChange = ({ target: { value } }) => {
    const nextState = { value, isInvalid: this.state.isInvalid };
    if (this.state.isInvalid && URLUtils.isUrl(URLUtils.normalizeUrl(value))) {
      nextState.isInvalid = false;
    }
    this.setState(nextState);
  };

  onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const value = e.clipboardData.getData('text');

    if (value === this.state.value) {
      const nextState = { value, isInvalid: this.state.isInvalid };
      if (this.state.isInvalid && URLUtils.isUrl(URLUtils.normalizeUrl(value))) {
        nextState.isInvalid = false;
      }
      this.setState(nextState);
      e.preventDefault();
    }
  };

  onClose = () => this.props.onOverrideContent(undefined);

  onMouseUp = event => {
    // event.preventDefault();
    event.stopPropagation();
  };

  onKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.submit();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      this.onClose();
    }
  };

  submit() {
    const { getEditorState, setEditorState, onRemoveLinkAtSelection } = this.props;
    let { value: url } = this.state;

    if (!url) {
      onRemoveLinkAtSelection();
    }

    if (!URLUtils.isMail(URLUtils.normaliseMail(url))) {
      url = URLUtils.normalizeUrl(url);

      if (!isPunycode(url) && !URLUtils.isUrl(url) && !URLUtils.isUrl(url)) {
        this.setState({ isInvalid: true });
        return;
      }

      if (isPunycode(url) && !URLUtils.isUrl(toUnicode(url))) {
        this.setState({ isInvalid: true });
        return;
      }
    } else {
      url = URLUtils.normaliseMail(url);
    }

    setEditorState(extendedCreateLinkFromSelection(getEditorState(), url, this.state.nofollow));
    this.input.blur();
    this.onClose();
  }

  render() {
    const { theme, placeholder, fromInline } = this.props;
    const { value, isInvalid, nofollow } = this.state;
    const className = isInvalid ? clsx(theme.input, theme.inputInvalid) : theme.input;

    return (
      <div
        tabIndex={-4}
        className={clsx(styles.linkFormWrapper, fromInline && styles.fromInline)}
        onKeyDown={this.onKeyDown}
        onBlur={event => {
          event.stopPropagation();
          event.preventDefault();
          console.log('event.currentTarget', event.currentTarget);
          console.log('event.relatedTarget', event.relatedTarget);
          // @ts-ignore
          if (!event.currentTarget.contains(event.relatedTarget)) {
            this.onClose();
          }
        }}
      >
        <Input
          tabIndex={-3}
          className={className}
          onClick={this.onMouseUp}
          onChange={this.onChange}
          onPaste={this.onPaste}
          onKeyDown={this.onKeyDown}
          placeholder={placeholder}
          ref={this.onRef}
          type="text"
          value={value}
          suffix={
            isInvalid ? (
              <Tooltip title="Неправильная ссылка">
                <InfoCircleOutlined style={{ color: 'rgba(250,10,10,0.45)' }} />
              </Tooltip>
            ) : (
              <span />
            )
          }
        />
        <div
          className={styles.nofollowCheckboxWrapper}
          // @ts-ignore
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
        >
          <Checkbox
            tabIndex={-1}
            checked={nofollow}
            onChange={changedValue => {
              this.setState({ nofollow: changedValue.target.checked });
            }}
          >
            <div
              // @ts-ignore
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex={-2}
            >
              Nofollow
            </div>
          </Checkbox>
        </div>
      </div>
    );
  }
}
