import { Col, DatePicker, Form, Input } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import React from 'react';

import { ItemWithLabel } from '~components';
import { Label, SmartSelect } from '~containers';

export const renderFormParams = (param, form) => {
  switch (param.type) {
    case 'label': {
      return (
        <Col span={24}>
          <Label title={param.title} />
        </Col>
      );
    }
    case 'text': {
      return param?.params?.map(innerParam => (
        <Col span={24} style={{ display: 'grid', alignItems: 'end' }}>
          <ItemWithLabel label={param.title}>
            <Form.Item
              name={innerParam}
              rules={[{ required: param.required, message: 'Обязательное поле!' }]}
            >
              <Input />
            </Form.Item>
          </ItemWithLabel>
        </Col>
      ));
    }
    case 'dateRange': {
      return param?.params?.map((innerParam, index) => (
        <Col xs={24} md={6} style={{ display: 'grid', alignItems: 'end' }} id="dateContainer">
          <ItemWithLabel label={index === 0 && param.title}>
            <Form.Item
              name={innerParam}
              rules={[{ required: param.required, message: 'Обязательное поле!' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                placeholder={index === 0 ? 'Начальная' : 'Конечная'}
                locale={locale}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                getPopupContainer={() => document.getElementById('dateContainer')}
              />
            </Form.Item>
          </ItemWithLabel>
        </Col>
      ));
    }
    case 'multiSelect': {
      return param?.params?.map((innerParam, index) => (
        <Col xs={24} md={12} style={{ display: 'grid', position: 'relative' }} id="selectContainer">
          <ItemWithLabel label={index === 0 && param.title}>
            <Form.Item
              name={innerParam}
              rules={[{ required: param.required, message: 'Обязательное поле!' }]}
            >
              <SmartSelect param={param} form={form} fieldName={innerParam} />
            </Form.Item>
          </ItemWithLabel>
        </Col>
      ));
    }
    case 'list': {
      return param?.params?.map((innerParam, index) => (
        <Col span={24} style={{ display: 'grid' }} id="selectContainer">
          <ItemWithLabel label={index === 0 && param.title}>
            <Form.Item
              name={innerParam}
              rules={[{ required: param.required, message: 'Обязательное поле!' }]}
            >
              <SmartSelect
                param={param}
                list={param?.list}
                defaultValue={param?.default}
                form={form}
                fieldName={innerParam}
              />
            </Form.Item>
          </ItemWithLabel>
        </Col>
      ));
    }
    default: {
      return null;
    }
  }
};
