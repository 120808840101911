import { Checkbox, Col, Row } from 'antd';
import classNames from 'classnames';
import { not, pathOr, pipe, propOr } from 'ramda';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { isAllTruthy } from '~utils';

import styles from './styles.module.scss';

const actionRights = [
  {
    name: 'Публикация/отложенная публикация/снятие с публикации материалов',
    path: 'posts',
    updateAction: 'publish',
    view: true,
  },
  {
    name: 'Публикация/снятие с публикации видеотрансляции',
    path: 'videos',
    updateAction: 'publish',
    view: true,
  },
  {
    name: 'Публикация/снятие с публикации тестовые трансляции',
    path: 'textTranslations',
    updateAction: 'publish',
    view: true,
  },
  {
    name: 'Публикация/снятие с публикации опроса',
    path: 'quiz',
    updateAction: 'publish',
    view: true,
  },
  {
    name:
      'Настройка шапки публикации (тип, приоритет, раздел, категории, подкатегории, теги, авторы, регионы)',
    path: 'posts',
    updateAction: 'settings',
    view: true,
  },
  {
    name: 'Флаги RSS при редактировании публикации',
    path: 'posts',
    updateAction: 'rss',
    view: true,
  },
  {
    name: 'Флаги кроспостинга при редактировании публикации',
    path: 'posts',
    updateAction: 'channels',
    view: true,
  },
  {
    name: 'Флаг отключения рекламы, комментариев, отправки пуш-уведомлений',
    path: 'posts',
    updateAction: 'flagsAdCommentPushRKN',
    view: true,
  },
  {
    name: 'Флаг темной стороны, adult, criminal, широкий формат, скрыть обложку',
    path: 'posts',
    updateAction: 'flagsBlackAdultCriminalWideCover',
    view: true,
  },
  {
    name: 'Валидация корректором',
    path: 'posts',
    updateAction: 'validateCorrector',
    view: true,
  },
  {
    name: 'Разблокировка блоков',
    path: 'posts',
    updateAction: 'unlockBlocks',
    view: true,
  },
  {
    name: 'Доступ ко всем публикациям',
    path: 'posts',
    viewAction: 'all',
    edit: true,
  },
  {
    name: 'Доступ к общим настройкам (первая вкладка настроек)',
    path: 'global',
  },
  {
    name: 'Настройки — таймеры',
    path: 'timers',
  },
  {
    name: 'Настройки — символы',
    path: 'symbols',
  },
  {
    name: 'Настройки — флаги',
    path: 'flags',
  },
  {
    name: 'Настройки — уведомления',
    path: 'notifications',
  },
  {
    name: 'Миграция публикаций',
    path: 'movePost',
  },
  {
    name: 'Редиректы',
    path: 'redirects',
  },
  {
    name: 'Эксперименты',
    path: 'experiments',
  },
  {
    name: 'Отправка в IndexNow',
    path: 'indexNow',
    view: true,
  },
  {
    name: 'Порядок подбора статей',
    path: 'queryOrder',
  },
  {
    name: 'Обратная связь',
    path: 'feedbacks',
  },
  {
    name: 'Комментарии',
    path: 'comments',
  },
  {
    name: 'Пользователи',
    path: 'users',
    view: true,
  },
  {
    name: 'Журнал изменений',
    path: 'history',
    edit: true,
  },
  {
    name: 'Флаг настройки noindex',
    path: 'posts',
    updateAction: 'noindex',
    view: true,
  },
];

interface ActionProps {
  edit?: boolean;
  name: string;
  path: string;
  updateAction?: string;
  view?: boolean;
  viewAction?: string;
}

const ActionRow: React.FC<ActionProps> = ({
  name,
  path,
  updateAction = null,
  viewAction = null,
  view = false,
  edit = false,
}) => {
  const { control } = useFormContext();

  return (
    <Row justify="space-between" className={styles.row}>
      <Col span={18}>{name}</Col>
      <Col span={3} className={styles.center}>
        <Controller
          name={`rights.${path}.${viewAction ?? 'view'}`}
          control={control}
          render={field => (
            <Checkbox
              {...field}
              onChange={e => field.onChange(e.target.checked)}
              checked={view ? true : field.value}
              disabled={view}
            />
          )}
        />
      </Col>
      <Col span={3} className={styles.center}>
        <Controller
          name={`rights.${path}.${updateAction ?? 'update'}`}
          control={control}
          render={field => (
            <Checkbox
              {...field}
              onChange={e => field.onChange(e.target.checked)}
              checked={edit ? true : field.value}
              disabled={edit}
            />
          )}
        />
      </Col>
    </Row>
  );
};

export const RightsActionsTab = () => {
  const { watch, setValue } = useFormContext();
  const [allView, setAllView] = useState(false);
  const [allUpdate, setAllUpdate] = useState(false);

  const values = watch();

  const onViewAll = ({ currentTarget }: React.MouseEvent<HTMLInputElement>) => {
    actionRights
      .filter(({ view }) => !view)
      .forEach(({ path, viewAction }) => {
        setValue(`rights.${path}.${viewAction ?? 'view'}`, currentTarget.checked);
      });
  };

  const onUpdateAll = ({ currentTarget }: React.MouseEvent<HTMLInputElement>) => {
    actionRights
      .filter(({ edit }) => !edit)
      .forEach(({ path, updateAction }) => {
        setValue(`rights.${path}.${updateAction ?? 'update'}`, currentTarget.checked);
      });
  };

  useEffect(() => {
    setAllView(
      isAllTruthy(
        actionRights
          .filter(pipe(propOr(false, 'view'), not))
          .map(({ path, viewAction }) =>
            pathOr(false, ['rights', path, viewAction ?? 'view'], values),
          ),
      ),
    );

    setAllUpdate(
      isAllTruthy(
        actionRights
          .filter(pipe(propOr(false, 'edit'), not))
          .map(({ path, updateAction }) =>
            pathOr(false, ['rights', path, updateAction ?? 'update'], values),
          ),
      ),
    );
  }, [values]);

  return (
    <>
      <Row gutter={[20, 10]}>
        <Col span={24} className={styles.activeAll}>
          Применить ко всем:
        </Col>
        <Col>
          <Checkbox onClick={onViewAll} checked={allView} /> Чтение
        </Col>
        <Col>
          <Checkbox onClick={onUpdateAll} checked={allUpdate} /> Редактирование
        </Col>
      </Row>
      <Row justify="space-between" className={classNames(styles.row, styles.header)}>
        <Col span={18}>Действие</Col>
        <Col span={3} className={styles.center}>
          Чтение
        </Col>
        <Col span={3} className={styles.center}>
          Редактирование
        </Col>
      </Row>
      {actionRights.map((item, index) => (
        <ActionRow {...item} key={index} />
      ))}
    </>
  );
};
