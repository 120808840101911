import { Typography } from 'antd';
import React from 'react';

export const Label = ({ title }) => {
  console.log('label', title);
  return (
    <div>
      <Typography.Title level={5}>{title}</Typography.Title>
    </div>
  );
};
