import { action, observable, runInAction } from 'mobx';

import API from '~api';

const RSS_URL = '/settings/rss';

export class RssStore {
  @observable rss = {
    brandAnalytics: false,
    exclusives: false,
    googleEditorsChoice: false,
    googleNews: false,
    googleNewsStand: false,
    instantFB: false,
    mailruNews: false,
    // mailruPulse: false,
    // mailruPulsePromo: false,
    mainFeed: false,
    yandexDzen: false,
    yandexNews: false,
    yandexNewsFiltered: false,
    yandexNewsArticle: false,
    yandexNewsSpb: false,
  };

  @observable isFirstLoading = true;

  @observable isRequesting = false;

  @observable dictionaries = [];

  @action
  fetchDictionaries = () => {
    const promise = API.get('dictionaries/settings');

    return promise.then(res => {
      const { data } = res;

      this.dictionaries = data.rssTypes;
    });
  };

  @action
  fetchRss = () => {
    const promise = API.get(RSS_URL);

    return promise
      .then(res => {
        const { data } = res.data;

        runInAction(() => {
          this.rss = data;
          this.isFirstLoading = false;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.isFirstLoading = false;
        });
      });
  };

  @action
  updateRssItem = (key, value) => {
    runInAction(() => {
      this.isRequesting = true;
    });

    const promise = API.patch(RSS_URL, {
      data: {
        [key]: value,
      },
    });

    promise.then(res => {
      const { data } = res.data;

      runInAction(() => {
        this.rss = data;
        this.isRequesting = false;
      });
    });
  };
}

export default new RssStore();
