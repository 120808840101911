import classNames from 'classnames';
import { EditorState } from 'draft-js';
import React from 'react';

import styles from '../styles.styl';

interface IProps {
  className?: string;
  entityKey?: string;
  getEditorState(): EditorState;
  target?: string;
}

const Link: React.FC<IProps> = ({ children, className, entityKey, getEditorState, target }) => {
  const entity = getEditorState()
    .getCurrentContent()
    .getEntity(entityKey || '');
  const entityData = entity ? entity.getData() : undefined;
  const href = (entityData && entityData.url) || undefined;
  const nofollow = (entityData && entityData.nofollow) || false;

  const rel = nofollow ? 'noopener noreferrer nofollow' : 'noopener noreferrer';

  return (
    <a
      className={classNames(className, nofollow && styles.nofollow)}
      title={href}
      href={href}
      target={target}
      rel={rel}
    >
      {children}
    </a>
  );
};

export default Link;
