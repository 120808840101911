import { convertToRaw, EditorState } from 'draft-js';
import React from 'react';

import { BlockType, IBlockComponent } from '~common';
import {
  Audio,
  Gallery,
  Heading2,
  Heading3,
  Image,
  Playlist,
  Quote,
  Text,
  Video,
  Widget,
} from '~icons';

export const TEXT_BLOCK = 'TEXT';
export const HEADER_BLOCK = 'HEADING';
export const TITLE_BLOCK = 'TITLE';
export const COVER_BLOCK = 'COVER';
export const CLICKBAIT_BLOCK = 'CLICKBAIT';
export const TEXT_DESCRIPTION = 'TEXT_DESCRIPTION';
export const SPECIAL_TITLE_BLOCK = 'SPECIAL_TITLE';
export const SUBTITLE_BLOCK = 'SUBTITLE';
export const IMAGE_BLOCK = 'IMAGE';
export const GALLERY_BLOCK = 'GALLERY';
export const VIDEO_BLOCK = 'VIDEO';
export const PLAYLIST_BLOCK = 'PLAYLIST';
export const WIDGET_BLOCK = 'WIDGET';
export const QUOTE_BLOCK = 'QUOTE';
export const AUDIO_BLOCK = 'AUDIO';
export const INCUT_BLOCK_TYPE = 'INCUT';
export const STREAM_BLOCK_TYPE = 'STREAM';
export const FLOW_BLOCK_TYPE = 'FLOW';
export const NEWS_BLOCK_TYPE = 'NEWS';
export const QUIZ_BLOCK_TYPE = 'QUIZ';
export const CUSTOM_BLOCK_TYPE = 'CUSTOM';

export const typeConstants = {
  TEXT_BLOCK,
  HEADER_BLOCK,
  SUBTITLE_BLOCK,
  IMAGE_BLOCK,
  GALLERY_BLOCK,
  VIDEO_BLOCK,
  PLAYLIST_BLOCK,
  WIDGET_BLOCK,
  QUOTE_BLOCK,
  AUDIO_BLOCK,
  QUIZ_BLOCK_TYPE,
};

export const defaultToolbarBlocks = [
  HEADER_BLOCK,
  TEXT_BLOCK,
  HEADER_BLOCK,
  IMAGE_BLOCK,
  GALLERY_BLOCK,
  VIDEO_BLOCK,
  PLAYLIST_BLOCK,
  WIDGET_BLOCK,
  QUOTE_BLOCK,
  AUDIO_BLOCK,
];

export const defaultBlocks = [
  COVER_BLOCK,
  CLICKBAIT_BLOCK,
  SPECIAL_TITLE_BLOCK,
  TITLE_BLOCK,
  SUBTITLE_BLOCK,
  QUIZ_BLOCK_TYPE,
  TEXT_DESCRIPTION,
];

export const defaultBlockComponents: IBlockComponent[] = [
  {
    component: IMAGE_BLOCK,
    type: BlockType.CoverBlock,
    icon: <Image />,
    blockProps: {
      title: 'Обложка',
    },
  },
  {
    component: HEADER_BLOCK,
    type: BlockType.ClickbaitBlock,
    icon: <Heading2 />,
    initialProps: {
      text: '',
      level: -2,
      richEditor: false,
    },
    blockProps: {
      title: 'Кликбейт',
    },
  },
  {
    component: HEADER_BLOCK,
    type: BlockType.TitleBlock,
    icon: <Heading2 />,
    initialProps: {
      text: '',
      level: 1,
      richEditor: false,
    },
    blockProps: {
      title: 'Заголовок',
    },
  },
  {
    component: HEADER_BLOCK,
    type: BlockType.SpecialTitleBlock,
    icon: <Heading2 />,
    initialProps: {
      text: '',
      level: -4,
      richEditor: false,
    },
    blockProps: {
      title: 'Спец заголовок',
    },
  },
  {
    component: HEADER_BLOCK,
    type: BlockType.SubtitleBlock,
    icon: <Heading2 />,
    initialProps: {
      text: '',
      level: -1,
      richEditor: false,
    },
    blockProps: {
      title: 'Подзаголовок',
      isLimitedByMaxSize: true,
    },
  },
  {
    component: HEADER_BLOCK,
    type: BlockType.HeadingBlock,
    icon: <Heading2 />,
    initialProps: {
      text: '',
      level: 2,
    },
    blockProps: {
      title: 'Заголовок второго уровня',
    },
  },
  {
    component: HEADER_BLOCK,
    type: BlockType.HeadingBlock,
    icon: <Heading3 />,
    initialProps: {
      text: '',
      level: 3,
    },
    blockProps: {
      title: 'Заголовок третьего уровня',
    },
  },
  {
    component: TEXT_BLOCK,
    type: BlockType.TextDescription,
    icon: <Text />,
    initialProps: {
      rawText: convertToRaw(EditorState.createEmpty().getCurrentContent()),
    },
    blockProps: {
      title: 'Текст (description)',
    },
  },
  {
    component: TEXT_BLOCK,
    type: BlockType.TextBlock,
    icon: <Text />,
    initialProps: {
      rawText: convertToRaw(EditorState.createEmpty().getCurrentContent()),
    },
    blockProps: {
      title: 'Текст',
    },
  },
  {
    component: IMAGE_BLOCK,
    type: BlockType.ImageBlock,
    icon: <Image />,
    blockProps: {
      title: 'Фото',
    },
  },
  {
    component: VIDEO_BLOCK,
    type: BlockType.VideoBlock,
    icon: <Video />,
    initialProps: {
      format: 'wide',
    },
    blockProps: {
      title: 'Видео',
    },
  },
  {
    component: PLAYLIST_BLOCK,
    type: BlockType.PlaylistBlock,
    icon: <Playlist />,
    initialProps: {
      video: '',
      player: '',
    },
    blockProps: {
      title: 'Плейлист Viqeo',
    },
  },
  {
    component: QUOTE_BLOCK,
    type: BlockType.QuoteBlock,
    icon: <Quote />,
    blockProps: {
      title: 'Цитата',
    },
  },
  {
    component: AUDIO_BLOCK,
    type: BlockType.AudioBlock,
    icon: <Audio />,
    blockProps: {
      title: 'Аудио',
    },
  },
  {
    component: GALLERY_BLOCK,
    type: BlockType.GalleryBlock,
    icon: <Gallery />,
    blockProps: {
      title: 'Галерея',
    },
  },
  {
    component: WIDGET_BLOCK,
    type: BlockType.WidgetBlock,
    icon: <Widget />,
    blockProps: {
      title: 'Виджет',
    },
  },
];
