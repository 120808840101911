import { message } from 'antd';
import { action, observable, runInAction } from 'mobx';

import API, { getData } from '~api';
import { showErrorNotificationInPromise } from '~utils';

const SETTINGS_VIQEO_URL = '/settings/viqeo';

export class SettingsViqeoStore {
  @observable viqeoSettings = {};

  @action
  fetchViqeoSettings = () => {
    const promise = API.get(SETTINGS_VIQEO_URL);

    return promise.then(getData).then(({ data }) => {
      this.viqeoSettings.websiteId = data?.websiteId;
      this.viqeoSettings.apiToken = data?.apiToken;
      this.viqeoSettings.playerTemplate = data?.playerTemplate;
    });
  };

  @action
  updateViqeoSettings = settings => {
    const promise = API.patch(SETTINGS_VIQEO_URL, { data: settings });
    return promise
      .then(getData)
      .then(({ data }) => {
        message.success('Настройки Viqeo изменены');

        this.viqeoSettings.websiteId = data?.websiteId;
        this.viqeoSettings.apiToken = data?.apiToken;
        this.viqeoSettings.playerTemplate = data?.playerTemplate;
      })
      .catch(showErrorNotificationInPromise({ title: 'Ошибка обновления настроек' }));
  };
}

export default new SettingsViqeoStore();
