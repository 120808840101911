import API from '~api';
import { EMPTY_STRING } from '~constants';
import ConfigureFetchUrlByListsStore from '~store/configureFetchUrlByLists.js';

const API_URL_REPORTS = '/report';

const getData = ({ data }) => data;

export class ReportsTransportLayer {
  getDictionaries = () => {
    return API.get(`${API_URL_REPORTS}/templates`).then(getData);
  };

  getReports = (defaultFilter = EMPTY_STRING) => {
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore;
    const promiseUrl = getPromiseUrl({ url: `${API_URL_REPORTS}/jobs`, defaultFilter });
    return API.get(promiseUrl).then(getData);
  };

  createReport = requestData => {
    return API.post(`${API_URL_REPORTS}/jobs`, { data: { ...requestData } }).then(getData);
  };
}
