import React from 'react';
import ContentWrapper from '~components/ContentWrapper';
import { Navigation, NavigationItem } from '~components/Navigation';
import { observer } from 'mobx-react';
import { useUserStore } from '~hooks';

const navigations = [
  { path: '/settings/common', text: 'Общие', authority: 'global.view' },
  { path: '/settings/social', text: 'Соцсети', authority: 'socials.view' },
  { path: '/settings/emails', text: 'E-mail адреса', authority: 'emails.view' },
  {
    path: '/settings/categories_and_sections',
    text: 'Разделы и категории',
    authority: [
      'categories.view',
      'subcategories.view',
      'sections.view',
      'headings.view',
      'headersPreviews.view',
    ],
  },
  { path: '/settings/timers', text: 'Таймеры', authority: 'timers.view' },
  {
    path: '/settings/infopages',
    text: 'Инфостраницы',
    authority: 'infoPages.view',
  },
  { path: '/settings/symbols', text: 'Символы', authority: 'symbols.view' },
  {
    path: '/settings/playlists',
    text: 'Плейлисты',
    authority: 'playlists.view',
  },
  {
    path: '/settings/specprojects',
    text: 'Спец.проекты',
    authority: 'specProjects.view',
  },
  { path: '/settings/flags', text: 'Флаги', authority: 'flags.view' },
  { path: '/settings/push', text: 'Уведомления', authority: 'push.view' },
  {
    path: '/settings/publications_migration',
    text: 'Миграция публикаций',
    authority: 'movePost.view',
  },
  { path: '/settings/redirects', text: 'Редиректы', authority: 'redirects.view' },
  { path: '/settings/experiments', text: 'Эксперименты', authority: 'experiments.view' },
  { path: '/settings/indexNow', text: 'Отправка в IndexNow', authority: 'indexNow.update' },
  { path: '/settings/queryOrder', text: 'Порядок подбора статей', authority: 'queryOrder.view' },
  {
    path: '/settings/notifications',
    text: 'Уведомления сотрудников',
    authority: 'notifications.view',
  },
  {
    path: '/settings/logo',
    text: 'Лого в хедере',
    authority: 'logo.view',
  },
  {
    path: '/settings/viqeo',
    text: 'Viqeo',
    authority: 'global.view',
  },
  {
    path: '/settings/reports',
    text: 'Отчет',
    authority: 'reports.view',
  },
];

const SettingsNavigation = observer(() => {
  const { checkPermissions } = useUserStore();

  return (
    <ContentWrapper>
      <Navigation title="Выберите страницу с настройками">
        {navigations.map(({ path, text, authority }) =>
          checkPermissions(authority) ? <NavigationItem path={path} text={text} /> : null,
        )}
      </Navigation>
    </ContentWrapper>
  );
});

export default SettingsNavigation;
