import { Button, Form, Input } from 'antd';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';

import { ItemWithLabel, SettingsNavigation } from '~components';
import { useViqeoSettings } from '~hooks/useViqeoSettings';

const { TextArea } = Input;

export const SettingsViqeoPage = observer(() => {
  const { viqeoSettings, fetchViqeoSettings, updateViqeoSettings } = useViqeoSettings();

  const [form] = Form.useForm();

  useEffect(() => {
    fetchViqeoSettings();
  }, [fetchViqeoSettings]);

  useEffect(() => {
    if (!isEmpty(viqeoSettings)) {
      const { websiteId, apiToken, playerTemplate } = viqeoSettings;
      form.setFieldsValue({
        websiteId,
        apiToken,
        playerTemplate,
      });
    }
  }, [form, viqeoSettings]);

  const handleSubmit = useCallback(
    async values => {
      updateViqeoSettings(values);
    },
    [updateViqeoSettings],
  );

  return (
    <>
      <SettingsNavigation />
      <Form onFinish={handleSubmit} style={{ marginTop: '30px' }} form={form}>
        <ItemWithLabel label="WebsiteId">
          <Form.Item name="websiteId">
            <Input placeholder="WebsiteId" />
          </Form.Item>
        </ItemWithLabel>

        <ItemWithLabel label="Token">
          <Form.Item name="apiToken">
            <Input placeholder="API token" />
          </Form.Item>
        </ItemWithLabel>

        <ItemWithLabel label="PlayerTemplate">
          <Form.Item name="playerTemplate">
            <TextArea autoSize placeholder="playerTemplate" />
          </Form.Item>
        </ItemWithLabel>

        <div>
          <Button htmlType="submit" type="primary">
            Сохранить изменения
          </Button>
        </div>
      </Form>
    </>
  );
});
