import { Select, Typography } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef } from 'react';

import { BlockType, IBlock } from '~common';
import { EMPTY_STRING } from '~constants';
import { useBlockStateReducer, useViqeoStore } from '~hooks';
import { noop } from '~utils';

import { BlockWrapper } from '../BlockWrapper';
import styles from './styles.scss';

interface IProps extends IBlock {
  isFetching: boolean;
  onChange: (data: any) => void;
  placeholder?: string;
  player: string;
  video: string;
}

type TState = Pick<IProps, 'video' | 'player'>;

const formatDuration = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
};

const PlaylistBlock: React.FC<IProps> = observer(props => {
  const {
    id = EMPTY_STRING,
    isEditing = false,
    isForceUpdate = false,
    placeholder = 'Выберите видео',
    onChange = noop,
    video = EMPTY_STRING,
    player = EMPTY_STRING,
  } = props;

  const { vqvideos, fetchPlayerTemplate } = useViqeoStore();

  const playerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (player && playerRef.current) {
      playerRef.current.innerHTML = player;

      const scripts = playerRef.current.getElementsByTagName('script');
      Array.from(scripts)?.forEach(script => {
        const newScript = document.createElement('script');
        newScript.src = script.src;
        newScript.async = true;
        document.body.appendChild(newScript);
      });
    }
  }, [player]);

  const initialState: TState = {
    video,
    player,
  };

  const data: TState = useMemo(
    () => ({
      video,
      player,
    }),
    [video, player],
  );

  const [state, dispatch] = useBlockStateReducer<TState>({
    blockId: id,
    blockType: BlockType.PlaylistBlock,
    incomingData: data,
    initialState,
    isEditing,
    isForceUpdate,
    onChange,
  });

  const handleSelectVideo = async (video: string) => {
    const player = await fetchPlayerTemplate(video);

    dispatch({
      video,
      player,
    });
  };

  return (
    <BlockWrapper title="Плейлист Viqeo" {...props.blockProps}>
      <div className={styles.root}>
        <Typography.Text type="secondary">Видео Viqeo</Typography.Text>
        <Select
          placeholder={placeholder}
          optionLabelProp="label" // Используем кастомную метку для каждой опции
          onChange={handleSelectVideo}
          value={state.video || undefined}
          className={styles.select}
        >
          {vqvideos?.map(item => (
            <Select.Option
              key={item.id}
              value={item.id}
              label={item.title}
              className={styles.selectOption}
            >
              <div className={styles.contentWrapper}>
                <img src={item.preview} alt={item.title} className={styles.image} />
                <span className={styles.title}>{item.title}</span>
                <Typography.Text type="secondary">{formatDuration(item.duration)}</Typography.Text>
              </div>
            </Select.Option>
          ))}
        </Select>
        <div ref={playerRef} />
      </div>
    </BlockWrapper>
  );
});

export default PlaylistBlock;
