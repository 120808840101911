import { Form, Modal, Row } from 'antd';
import { observer } from 'mobx-react';
import moment, { isMoment } from 'moment';
import React, { FC, useCallback, useMemo } from 'react';
import { createModal } from 'react-modal-promise';

import { InjectedModalProps } from '~common';
import { useReportsStore, useWindowDimensions } from '~hooks';
import { renderFormParams } from '~utils/renderFormParams';

const ReportsModal: FC<InjectedModalProps> = observer(props => {
  const { isOpen, close, name: reportName } = props;

  const { reportTemplates, createReport, fetchReports } = useReportsStore();
  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => width < 480, [width]);

  const currentReportTemplate = reportTemplates.find(template => template.name === reportName);

  if (!currentReportTemplate) return null;

  const [form] = Form.useForm();

  const onClose = useCallback(
    async (result: any = []) => {
      if (result) {
        await fetchReports();
      }
      close && close(result);
    },
    [close, fetchReports],
  );

  const handleClose = useCallback(() => {
    form.resetFields();
    onClose(true);
  }, [form, onClose]);

  const getReportParams = values =>
    Object.entries(values).reduce((acc, [key, value]) => {
      let val = value;

      if (isMoment(value)) {
        val = moment(value).format('YYYY-MM-DD');
      } else if (typeof value === 'string') {
        val = value.trim();

        if (val === '') {
          return acc;
        }
      }

      return {
        ...acc,
        [key]: val,
      };
    }, {});

  const handleSubmit = useCallback(() => {
    form.validateFields().then(values => {
      const report = {
        name: currentReportTemplate?.name,
        params: getReportParams(values),
      };

      createReport(report).then(handleClose);
    });
  }, [createReport, currentReportTemplate?.name, form, handleClose]);

  return (
    <Modal
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getContainer={() => document.getElementById('modal-container')}
      destroyOnClose
      visible={isOpen}
      onCancel={handleClose}
      onOk={handleSubmit}
      title={<div style={{ fontWeight: 700 }}>{currentReportTemplate?.title}</div>}
      maskClosable={false}
      width={680}
      okText="Сформировать"
      cancelText="Отмена"
      style={{ maxWidth: isMobile ? '100%' : 'inherit', fontWeight: 700 }}
      bodyStyle={{ padding: isMobile ? '20px 12px' : '20px' }}
    >
      <Form form={form}>
        <Row gutter={[10, 10]}>
          {currentReportTemplate?.params?.map(param => {
            return renderFormParams(param, form);
          })}
        </Row>
      </Form>
    </Modal>
  );
});

export const openReportsModal = createModal(ReportsModal);
