import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import { Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import styles from './styles.scss';

const ClickAndViewButton = ({
  history,
  editLink,
  onView,
  clickButtonText,
  isLoadingView,
  disableView = false,
}) => {
  const handleClick = event => {
    if (event.ctrlKey || event.metaKey || (event.button && event.button === 1)) {
      window.open(editLink);
    } else {
      history.push(editLink);
    }

    return null;
  };

  return (
    <div className={styles.root}>
      <Button
        className={styles.clickButton}
        type="primary"
        // onClick={handleClick}
        onMouseDown={e => {
          if (e.button === 1) {
            e.preventDefault();
            e.stopPropagation();

            return false;
          }
        }}
        onMouseUp={handleClick}
      >
        {clickButtonText}
      </Button>

      {onView && (
        <Fragment>
          <div className={styles.divider} />
          <Button
            className={styles.viewButton}
            loading={isLoadingView}
            type="primary"
            onClick={onView}
            disabled={disableView}
            icon={<EyeOutlined />}
          />
        </Fragment>
      )}
    </div>
  );
};

export default withRouter(ClickAndViewButton);

ClickAndViewButton.propTypes = {
  clickButtonText: PropTypes.string,
  editLink: PropTypes.string,
  onView: PropTypes.func,
  isLoadingView: PropTypes.bool,
  history: PropTypes.any,
  disableView: PropTypes.bool,
};

ClickAndViewButton.defaultProps = {
  clickButtonText: 'Редактировать',
  disableView: false,
};
