import { action, observable, reaction } from 'mobx';

import API, { getData } from '~api';

import PublicationStore from './PublicationStore';

export class ViqeoStore {
  @observable
  vqvideos: any = [];

  fetchInterval: NodeJS.Timeout | undefined;

  // eslint-disable-next-line no-useless-constructor
  constructor() {
    reaction(
      () => PublicationStore.tags.length,
      () => {
        const tagsQuery = PublicationStore.tags.map(tag => `tags=${tag.title}`).join('&');
        this.fetchVideos(tagsQuery);

        if (this.fetchInterval) {
          clearInterval(this.fetchInterval);
        }

        this.fetchInterval = setInterval(() => {
          this.fetchVideos(tagsQuery);
        }, 10000);
      },
    );
  }

  @action
  fetchVideos = async (tagsQuery = '') => {
    if (tagsQuery.length) {
      const page = 1;
      const promise = API.get(`viqeo/videos?page=${page}&${tagsQuery}`);
      return promise.then(getData).then(({ data }) => {
        this.vqvideos = data;
      });
    }

    this.vqvideos = [];
    return this.vqvideos;
  };

  @action
  fetchPlayerTemplate = async (videoId: string) => {
    const promise = API.post(`viqeo/player`, { video: videoId, type: 'viqeo' });
    return promise.then(getData).then(data => {
      return data;
    });
  };

  @action
  setVideos = (data: any) => {
    this.vqvideos = data;
  };
}

export default new ViqeoStore();
