import { Select } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import API from '~api';
import { debounce } from '~utils';

export const SmartSelect = ({
  param,
  list = undefined,
  defaultValue = undefined,
  form,
  fieldName,
}) => {
  const [selectData, setSelectData] = useState(null);
  const [fetchUrl, setFetchUrl] = useState<string | undefined>(undefined);

  const isMultiselect = param.type === 'multiSelect';

  useEffect(() => {
    switch (param?.source?.type) {
      case 'dictionary': {
        setFetchUrl(`/dictionaries/${param.source.value.split('.')[0]}`);
        break;
      }
      case 'route': {
        setFetchUrl(`${param.source.value}`);
        break;
      }
      default: {
        break;
      }
    }
  }, [param?.source?.type, param?.source?.value]);

  const getData = useCallback(
    data => {
      switch (param?.source?.type) {
        case 'dictionary': {
          setSelectData(
            data?.data[param.source.value.split('.')[1]].map(option => {
              return { value: option.id || option._id, label: option.ru };
            }),
          );
          break;
        }
        case 'route': {
          setSelectData(
            data?.data?.data.map(option => {
              return { value: option.id || option._id, label: option.name || option.title };
            }),
          );
          break;
        }
        default: {
          break;
        }
      }
    },
    [param?.source?.type, param?.source?.value],
  );

  const fetchData = async () => {
    if (fetchUrl) {
      const data = await API.get(fetchUrl);

      switch (param.source.type) {
        case 'dictionary': {
          setSelectData(
            data?.data[param.source.value.split('.')[1]].map(option => {
              return { value: option.id || option._id, label: option.ru };
            }),
          );
          break;
        }
        case 'route': {
          setSelectData(
            data?.data?.data.map(option => {
              return { value: option.id || option._id, label: option.name || option.title };
            }),
          );
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  const fetchBySearch = useCallback(
    term => {
      if (fetchUrl) {
        let url = fetchUrl;

        if (term) {
          url = `${url}?search=${term}`;
        }

        return API.get(url).then(res => {
          getData(res);
        });
      }
      return [];
    },
    [fetchUrl, getData],
  );

  const onSearch = useMemo(
    () =>
      debounce(term => {
        fetchBySearch(term);
      }, 1000),
    [fetchBySearch],
  );

  useEffect(() => {
    if (fetchUrl) {
      fetchData().catch(console.error);
    }
    if (list) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const preparedList = list?.map(listItem => {
        return { value: listItem.id, label: listItem.title };
      });

      setSelectData(preparedList);
      form.setFieldsValue({ [fieldName]: defaultValue });
    }
  }, [fetchUrl, param?.source?.type, param?.source?.value]);

  return (
    <Select
      mode={!isMultiselect ? undefined : 'multiple'}
      filterOption={false}
      onSearch={onSearch}
      notFoundContent={null}
      defaultValue={defaultValue}
      options={selectData || []}
      showSearch={isMultiselect}
      placeholder="Выбрать"
      allowClear={isMultiselect}
      onBlur={() => {
        isMultiselect && fetchData();
      }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getPopupContainer={() => document.getElementById('selectContainer')}
      onChange={value => {
        form.setFieldsValue({ [fieldName]: value });
        return value;
      }}
    />
  );
};
