import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { ContentWrapper, HeaderPageSearchBar, SettingsNavigation } from '~components';
import { InfinityScroll } from '~components/InfinityScroll/ts';
import { openReportsModal, ReportsFilter } from '~containers';
import ReportsList from '~containers/Reports/ReportsList/ReportsList';
import { useReportsStore } from '~hooks';
import s from '~pages/SettingsMovePublicationsPage/styles.scss';

const PAGE_TITLE = 'Отчеты';
const SEARCH_BTN_TEXT = 'Найти в отчетах';
const CREATE_BTN_TEXT = 'Создать отчет';

const SettingsReportsPage = observer(() => {
  const {
    fetchDictionaries,
    fetchReports,
    isFetching,
    allItemsCount,
    reportsLength,
  } = useReportsStore();

  useEffect(() => {
    fetchDictionaries();
  }, [fetchDictionaries]);

  return (
    <>
      <SettingsNavigation />
      <ContentWrapper title={PAGE_TITLE}>
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <HeaderPageSearchBar
              searchButtonText={SEARCH_BTN_TEXT}
              createButtonText={CREATE_BTN_TEXT}
              onCreateElement={openReportsModal}
              resourceType="report"
              onSearch={fetchReports}
            />
          </Col>
          <Col span={24}>
            <Row align="top" gutter={24}>
              <Col xs={{ span: 24, order: 3 }} lg={{ span: 16, order: 2 }}>
                <InfinityScroll
                  isFetching={isFetching}
                  fetchItems={fetchReports}
                  hasMore={allItemsCount !== reportsLength}
                >
                  <ReportsList />
                </InfinityScroll>
              </Col>
              <Col
                xs={{ span: 24, order: 1 }}
                lg={{ span: 8, order: 3 }}
                className={s.historyContentColumn}
              >
                <ReportsFilter />
              </Col>
            </Row>
          </Col>
        </Row>
      </ContentWrapper>
    </>
  );
});

export default SettingsReportsPage;
