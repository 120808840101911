import { array, mixed, object, string } from 'yup';
import { EMPTY_ARRAY, EMPTY_STRING } from '~constants/index';
import { compiledFlowSchema, compiledRegionSchema, compiledTagSchema, flagsSchema } from '~schemas';
import { draftPrioritiesSchema } from '~schemas/draftPriority';
import { makeDictionary } from '~schemas/transformations/makeDictionary';
import { normalizeCollection, normalizeOne } from '~schemas/transformations/normalize';
import { strippedSchemaWhenNull } from '~schemas/transformations/stripSchemaWhenNull';
import { castBySchema } from '~utils/castBySchema';

const RE_LATIN_CHARS_AND_DASH = /^[0-9a-zA-Z\\-]+$/;

/** @type object */
export const settingsBlockSchema = object()
  .default(undefined)
  .shape({
    advType: mixed().transform(normalizeOne('id')),
    // yandexTag: mixed().transform(normalizeOne('id')),
    alias: string()
      .default(EMPTY_STRING)
      .defined()
      .min(1)
      .matches(RE_LATIN_CHARS_AND_DASH),
    authors: array()
      .default(EMPTY_ARRAY)
      .transform(makeDictionary('name')),
    categories: array()
      .default(EMPTY_ARRAY)
      .transform(normalizeCollection()),
    subcategories: array()
      .default(EMPTY_ARRAY)
      .transform(normalizeCollection()),
    coauthors: array()
      .default(EMPTY_ARRAY)
      .transform(makeDictionary('name')),
    draftPriorities: draftPrioritiesSchema,
    end: strippedSchemaWhenNull(string().default(EMPTY_STRING)),
    flags: flagsSchema,
    flows: array()
      .default(EMPTY_ARRAY)
      .of(compiledFlowSchema),
    note: string().default(EMPTY_STRING),
    priority: mixed().when('$realPriority', (realPriority, schema) => {
      if (realPriority) {
        return schema.transform(() => realPriority);
      } else {
        return schema.transform(normalizeOne('id'));
      }
    }),
    publicationDate: string().defined(),
    section: mixed().transform(normalizeOne()),
    start: strippedSchemaWhenNull(string().default(EMPTY_STRING)),
    tags: array()
      .default(EMPTY_ARRAY)
      .of(compiledTagSchema),
    channels: array().default(EMPTY_ARRAY),
    type: mixed().transform(normalizeOne('id')),
    regions: array()
      .default(EMPTY_ARRAY)
      .of(compiledRegionSchema),
    // newsLinks: array().of(string()),
  });

export const castBySettingsBlockSchema = castBySchema(settingsBlockSchema, { stripUnknown: true });
