import { get, groupBy, isArray } from 'lodash';
import { action, computed, observable, set } from 'mobx';
import moment from 'moment';

import API from '~api';

import getIsGridValue from '~utils/getIsGridValue';
import { role as allRoles } from '~utils/roles';

import ConfigureFetchUrlByListsStore from './configureFetchUrlByLists';
import FetchPaginationStore from './fetchPaginationStore';
import FilterParamsByListStore from './filterParamsByList';
import SortingStore from './SortingStore';
import UserStore from './UserStore';
import { showErrorNotificationInPromise, toArray } from '~utils';
import { notification } from 'antd';

export const PUBLICATIONS_URL = '/posts';
export const PUBLICATIONS_EXPORT_URL = '/posts/exportFromRegister';

const defaultFilters = [
  {
    label: 'Тип поста',
    type: 'select',
    name: 'filter.type',
    fix: true,
    mode: 'multiple',
  },
  {
    label: 'Статус',
    type: 'select',
    name: 'filter.status',
    fix: true,
    mode: 'multiple',
  },
  {
    label: 'Авторы',
    type: 'select',
    name: 'filter.authors',
    fix: true,
    mode: 'multiple',
  },
  {
    label: 'Категории',
    type: 'select',
    name: 'filter.categories',
    fix: true,
    mode: 'multiple',
  },
  {
    label: 'Подкатегории',
    type: 'select',
    name: 'filter.subcategories',
    fix: true,
    mode: 'multiple',
  },
  // {
  //   label: 'Тег для Яндекс.Статьи',
  //   type: 'select',
  //   name: 'filter.yandexTag',
  //   mode: 'multiple',
  // },
  {
    label: 'Теги',
    type: 'select',
    name: 'filter.tags',
    mode: 'multiple',
  },
  {
    label: 'Раздел',
    type: 'select',
    name: 'filter.section',
    mode: 'multiple',
  },
  {
    label: 'Приоритет поста',
    type: 'select',
    name: 'filter.priority',
    mode: 'multiple',
  },
  {
    label: 'Вычитка корректором',
    type: 'select',
    name: 'filter.validatedBy.corrector',
  },
  {
    label: 'Дата создания',
    type: 'dateRange',
    name: 'filter.createdAt',
  },
  {
    label: 'Дата изменения',
    type: 'dateRange',
    name: 'filter.updatedAt',
  },
  {
    label: 'Дата публикации',
    type: 'dateRange',
    name: 'filter.publicationDate',
  },
  {
    label: 'Регионы',
    type: 'select',
    name: 'filter.regions',
    mode: 'multiple',
  },
  {
    label: 'Комментарии в посте',
    type: 'bool',
    name: 'filter.flags.commentsAllowed',
  },
  {
    label: 'Noindex',
    type: 'bool',
    name: 'filter.flags.noindex',
  },
  {
    label: 'Отключить рекламу в публикации',
    type: 'bool',
    name: 'filter.flags.advDisabled',
  },
  {
    label: 'Adult',
    type: 'bool',
    name: 'filter.flags.adultContent',
  },
  // {
  //   label: 'Блокировка РКН',
  //   type: 'bool',
  //   name: 'filter.flags.blockedByRKN',
  // },
  {
    label: 'Список флагов RSS',
    type: 'select',
    name: 'filter.flags.RSS',
    mode: 'multiple',
  },
  {
    label: 'Пуш уведомления',
    type: 'bool',
    name: 'filter.flags.pushAllowed',
  },
  // {
  //   label: 'Темная сторона',
  //   type: 'bool',
  //   name: 'filter.flags.darkSide',
  // },
  {
    label: 'Criminal',
    type: 'bool',
    name: 'filter.flags.criminalContent',
  },
  // {
  //   label: 'Широкий формат',
  //   type: 'bool',
  //   name: 'filter.flags.isWide',
  // },
  {
    label: 'Скрыта обложка',
    type: 'bool',
    name: 'filter.flags.coverHidden',
  },
];

export class PublicationsStore {
  constructor() {
    const startUrl = new URLSearchParams(window.location.search);

    startUrl.forEach((value, key) => {
      const filterFromUrl = this.filtersItem.find(item => item.name === key && !item.fix);

      if (filterFromUrl) {
        this.setFilterItemActive(filterFromUrl);
      }
    });
  }

  initFilterParams = () => {
    const { SET_FILTER_PARAMS_FOR_BACK } = ConfigureFetchUrlByListsStore;

    SET_FILTER_PARAMS_FOR_BACK({
      'filter.updatedAt': params => this.getDateRangeFilterParams(params),
      'filter.createdAt': params => this.getDateRangeFilterParams(params),
      'filter.publicationDate': params => this.getDateRangeFilterParams(params),
      'filter.title': params => `match(${params})`,
    });
  };

  getDateRangeFilterParams = params => {
    const dateArray = isArray(params) ? params : params.split(',');

    const startDate = moment(dateArray[0])
      .startOf('day')
      .utc()
      .format();

    const endDate = moment(dateArray[1])
      .endOf('day')
      .utc()
      .format();

    return `between(["${startDate}","${endDate}"])`;
  };

  @observable isFirstLoading = true;

  @observable isGrid = getIsGridValue('isGridViewPublications');

  @observable viewType = localStorage.getItem('postsViewType') || 'list';

  @observable isShowFilter = this.getIsShowFilterFromUrlParams();

  @observable isLoadingMore = false;

  @observable allItemsCount = 0;

  @observable publications = [];

  @observable filtersItem = defaultFilters;

  @observable selectedFilterItems = [];

  @observable dictionaries = [];

  @action
  fetchDictionaries = () => {
    const promisePost = API.get('/dictionaries/post');
    const promiseSettings = API.get('/dictionaries/settings');

    return Promise.all([promisePost, promiseSettings]).then(([postResult, settingsResult]) => {
      this.setDictionaries({ ...postResult.data, rssTypes: settingsResult.data.rssTypes });
    });
  };

  @action
  getPreviewToken = id => {
    return API.get(`posts/${id}/preview`);
  };

  @action
  getIsShowFilterFromUrlParams = () => {
    const { filterParams } = FilterParamsByListStore;
    const EXCLUDED_KEYS = ['search', 'sort'];
    const filter = Object.keys(filterParams).filter(item => !EXCLUDED_KEYS.includes(item));
    return !!filter.length;
  };

  @action
  fetchPublications = (defaultFilter = '') => {
    const { offset } = FetchPaginationStore;
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore;
    const { SET_FILTER_PARAMS_FOR_BACK, FILTER_PARAMS_FOR_BACK } = ConfigureFetchUrlByListsStore;

    let customDefaultFilter = defaultFilter;

    if (this.viewType === 'calendar') {
      const { filterParams } = FilterParamsByListStore;

      const filterPubDate = get(filterParams, 'filter.publicationDate', null);

      if (!filterPubDate) {
        customDefaultFilter = `${customDefaultFilter}&filter.publicationDate=gt(${moment().format(
          'YYYY-MM-DD',
        )})`;
      } else {
        customDefaultFilter = defaultFilter;
      }
    }

    SET_FILTER_PARAMS_FOR_BACK({
      ...FILTER_PARAMS_FOR_BACK,
      'filter.validatedBy.corrector': params => {
        if (String(params) === '1') {
          return `eq(null)`;
        }

        if (String(params) === '2') {
          return `neq(null)&filter.validatedAt.corrector=neq(null)`;
        }

        if (String(params) === '3') {
          return `neq(null)&filter.validatedAt.corrector=eq(null)`;
        }

        return '';
      },
      'filter.flags.RSS': params =>
        `exists(true)${toArray(params)
          .map(rssType => `&filter.flags.RSS.${rssType}=true`)
          .join('')}`,
    });

    let promiseUrl = getPromiseUrl({
      url: PUBLICATIONS_URL,
      defaultFilter: customDefaultFilter,
    });

    const fetchPublicationsPromise = API.get(promiseUrl);

    this.setIsLoadingMore(true);
    return fetchPublicationsPromise
      .then(res => {
        const { data, meta } = res.data;

        if (offset === 0) {
          this.setPublication(data);
        } else {
          this.setPublication([...this.publications, ...data]);
        }

        this.setAllItemsCount(meta.count);
        this.setIsFirstLoading(false);
        this.setIsLoadingMore(false);
      })
      .catch(
        showErrorNotificationInPromise({
          title: 'Ошибка загрузки приоритетов',
          callback: () => {
            this.setIsFirstLoading(false);
            this.setIsLoadingMore(false);
            this.setPublication([]);
          },
        }),
      );
  };

  @action
  exportPublications = (defaultFilter = '') => {
    const { getPromiseUrl } = ConfigureFetchUrlByListsStore;
    const { SET_FILTER_PARAMS_FOR_BACK, FILTER_PARAMS_FOR_BACK } = ConfigureFetchUrlByListsStore;

    let customDefaultFilter = defaultFilter;

    if (this.viewType === 'calendar') {
      const { filterParams } = FilterParamsByListStore;

      const filterPubDate = get(filterParams, 'filter.publicationDate', null);

      if (!filterPubDate) {
        customDefaultFilter = `${customDefaultFilter}&filter.publicationDate=gt(${moment().format(
          'YYYY-MM-DD',
        )})`;
      } else {
        customDefaultFilter = defaultFilter;
      }
    }

    SET_FILTER_PARAMS_FOR_BACK({
      ...FILTER_PARAMS_FOR_BACK,
      'filter.validatedBy.corrector': params => {
        if (String(params) === '1') {
          return `eq(null)`;
        }

        if (String(params) === '2') {
          return `neq(null)&filter.validatedAt.corrector=neq(null)`;
        }

        if (String(params) === '3') {
          return `neq(null)&filter.validatedAt.corrector=eq(null)`;
        }

        return '';
      },
      'filter.flags.RSS': params =>
        `exists(true)${toArray(params)
          .map(rssType => `&filter.flags.RSS.${rssType}=true`)
          .join('')}`,
    });

    const { user: { role, _id } = { role: '', _id: '' } } = UserStore;
    const filterByUser = role === allRoles.INTERN ? `&filter.createdBy=${_id}` : '';

    let promiseUrl = getPromiseUrl({
      url: PUBLICATIONS_EXPORT_URL,
      defaultFilter: customDefaultFilter + filterByUser,
    });

    const exportPublicationsPromise = API.get(promiseUrl);

    notification.warn({
      message: 'Запущена выгрузка постов с фильтрами',
      description: 'Не закрывайте и не обновляйте вкладку до получения файла выгрузки',
      duration: 0,
      placement: 'topRight',
    });

    exportPublicationsPromise
      .then(res => {
        let filename = '';
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(res.headers['content-disposition']);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
        return new File([res.data], filename, { type: 'text/csv;charset=utf-8;' });
      })
      .then(blob => {
        const file = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.download = blob.name;
        a.href = file;
        a.click();
      });
  };

  @computed
  get isEmptyPublications() {
    return !this.publications.length;
  }

  @computed
  get publicationLength() {
    return this.publications.length;
  }

  @action
  setPublication = data => {
    this.publications = data;
  };

  @action
  setIsFirstLoading = value => {
    this.isFirstLoading = value;
  };

  @action
  setAllItemsCount = count => {
    this.allItemsCount = count;
  };

  @action
  setIsLoadingMore = value => {
    this.isLoadingMore = value;
  };

  @action
  onToggleView = () => {
    this.isGrid = !this.isGrid;
    localStorage.setItem('isGridViewPublications', this.isGrid ? 'yes' : 'no');
  };

  @action
  toggleIsShowFilter = () => {
    this.isShowFilter = !this.isShowFilter;
  };

  @action
  setHideFilter = () => {
    this.isShowFilter = false;
  };

  @action
  setShowFilter = () => {
    this.isShowFilter = true;
  };

  @action
  setFilterItemActive = item => {
    this.selectedFilterItems.push(item);
  };

  @action
  removeFileItem = item => {
    this.selectedFilterItems.remove(item);
  };

  @action
  setDictionaries = data => {
    this.dictionaries = data;
  };

  @action
  setFixedFilter = item => {
    set(item, { fix: true });
  };

  @action
  resetFilters = () => {
    this.filtersItem = defaultFilters;
  };

  @action
  setViewType = type => {
    this.viewType = type;
    localStorage.setItem('postsViewType', type);

    this.setDefaultFilterByCalendarView();
  };

  @action
  setDefaultFilterByCalendarView = () => {
    const { options, toggleOptions } = SortingStore;

    if (this.viewType === 'calendar') {
      if (!options[1].value) {
        toggleOptions(options[1]);
      }
    }
  };

  @computed
  get groupedData() {
    const eventGroupByDate = groupBy(this.publications, item =>
      moment(item.publicationDate).format('DD MMM YYYY'),
    );

    const resultGroupedPosts = Object.keys(eventGroupByDate).reduce((memo, key) => {
      memo.push({
        date: key,
        posts: eventGroupByDate[key],
      });

      return memo;
    }, []);

    return resultGroupedPosts;
  }
}

export default new PublicationsStore();
